webix.protoUI({
	name: "ssheet-align",
	$cssName:"richselect",
	$init: function(config){
		config.options =  {
			view:"datasuggest",
			body:{
				view: "ssheet-icons",
				tooltip: {
					template:"#tooltip#"
				},
				xCount:3, yCount:1
			},
			data: config.data
		};
	}
},webix.ui.richselect);