import * as nms from "../helpers/column_names";
import {split} from "../math/parser";

export function updateMath(formula, action, area){
	var stack = split(formula);
	var max = stack.length;
	var start = area ? area.start : null;
	var end = area ? area.end : null;
	for (var i = 1; i < max; i+=2) {
		var [row, column, flags] = stack[i];

		const isRowFix = flags & 1;
		const isColumnFix = flags & 2;

		// for cut operations we need to check whether referenced values are inside cut block. if so - update them.
		if ((action.id === "move") && (!action.cut || (row >= start.row && row <= end.row && column >= start.column && column <= end.column))){
			column += isColumnFix ? 0 : action.column;
			row += isRowFix ? 0 : action.row;
		} else if (action.id === "row" && action.start <= row){
			row += isRowFix ? 0 : action.count;
		} else if (action.id === "column" && action.start <= column){
			column += isColumnFix ? 0 : action.count;
		}

		if (!column || !row)
			stack[i] = 0;
		else
			stack[i] = (isColumnFix?"$":"") + nms.encode[column] + (isRowFix?"$":"") + row;
	}

	return stack.join("");
}

export function updateSheetNames(formula, oldName, newName, extra){
	var stack = split(formula, true);
	for(var i = 0; i < stack.length; i++){
		if(webix.isArray(stack[i])){
			var thisSheet = stack[i][0] === oldName;
			if(!extra.multiSheet && thisSheet)
				extra.multiSheet = true;
			var sheet = thisSheet ? newName : stack[i][0];
			stack[i] = "'"+sheet+"'!"+stack[i][1];
		}
	}
	return stack.join("");
}