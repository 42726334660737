import {ui, buttonsMap} from "./buttons";

export function toolbarButtons(view,structure){
	var checkButtons = function(cells){
		for(var i =0; i < cells.length; i++){
			var name = cells[i].$button;
			if (name){
				var base = buttonsMap[name] ? buttonsMap[name](view) : ui.iconButton({name:name});
				webix.extend(cells[i], base);
			}
			
			if(cells[i].rows)
				checkButtons(cells[i].rows);
			if(cells[i].cols)
				checkButtons(cells[i].cols);
		}
	};
	checkButtons(structure);
	return structure;
}

export function getButtons(view,structure){
	var config = [];
	for(var block in structure){
		config.push(elementsBlock(view, block, structure[block]));
		config.push(ui.separator());
	}
	return config;
}

function blockColumns(view, buttons){
	var cols = [];
	for(var i =0; i < buttons.length; i++){
		let name = buttons[i];
		if(typeof name == "object"){
			cols.push(name);
		}
		else {
			var base = buttonsMap[name] ? buttonsMap[name](view) : ui.iconButton({name:name});
			cols.push(base);
		}
	}
	return cols;
}


function elementsBlock(view, name, columns){
	var block = {
		rows:[
			{
				padding:2,
				cols:[{
					margin: 2,
					cols: blockColumns(view, columns)
				}]
			},
			ui.title({title:name})
		]
	};
	return block;
}