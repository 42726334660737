export function IMAGE(url){
	return `<img class="webix_ssheet_cimage" src="${webix.template.escape(url)}">`;
}

export function SPARKLINE(arr, type, color1, color2){
	var i, config = { type: type, color: color1, negativeColor:color2  },
		width =  type == "pie"? 60: 150;
	for(i =0 ; i < arr.length; i++)
		arr[i] = arr[i]||0;
	return webix.Sparklines.getTemplate(config)(arr, { width:width, height: 35 });
}

export function LINK(url, text){
	text = text || url;
	return `<a target="blank" href="${webix.template.escape(url)}">${webix.template.escape(text)}</a>`;
}

export function IF(check, pos, neg){
	if (check)
		return pos;
	else
		return neg;	
}