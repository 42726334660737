export function expandSelection(r, view){
	let maxY = view.config.rowCount;
	let maxX = view.config.columnCount;
	let table = view._table;

	let nr1 = r.start.row, nr2 = r.end.row, nc1 = r.start.column, nc2 = r.end.column;

	for (let i=nr1-1; i>0; i--){
		if (getValue(i, r.start.column, table))
			nr1 = i;
		else
			break;
	}

	for (let i=nr2+1; i<maxY; i++){
		if (getValue(i, r.end.column, table))
			nr2 = i;
		else
			break;
	}

	for (let i=nc1-1; i>0; i--){
		if (getValue(r.start.row, i, table))
			nc1 = i;
		else
			break;
	}

	for (let i=nc2+1; i<maxX; i++){
		if (getValue(r.end.row, i, table))
			nc2 = i;
		else
			break;
	}

	let newrange = { start:{ row:nr1, column:nc1 } , end:{ row: nr2, column: nc2 }};
	if (nr1 != r.start.row || nr2 != r.end.row || nc1 != r.start.column || nc2 != r.end.column){
		view._table.addSelectArea(newrange.start, newrange.end);
	}

	return newrange;
}

function getValue(r,c, table){
	var value = table.getItem(r)[c];
	if(value === undefined || value === ""){
		return false;
	} else {
		return true;
	}
}