import {getStyle, getTextSize} from "./styles";
import {save} from "./save";

export function init(view){

	if (view.config.resizeCell){
		var grid = view.$$("cells");
		grid.define("resizeRow", { headerOnly: true, size: 10 });
		grid.define("resizeColumn", { headerOnly: true, size: 10 });
		grid.define("fixedRowHeight", false);

		grid.attachEvent("onRowResize", (id) => {
			view.$$("cells").refreshSelectArea();
			save(view, "sizes", { row: id, column: 0, size: view.getRow(id).$height });
		});
		grid.attachEvent("onColumnResize", (id) => {
			view.$$("cells").refreshSelectArea();
			save(view, "sizes", { row: 0, column: id, size: view.getColumn(id).width });
		});

		// undo
		view.attachEvent("onUndo", (type, row, column, value) => {
			if(type == "c-resize" || type == "r-resize")
				_undoResize(view, row, column, value);
		});
	}

	view.attachEvent("onDataParse", (data) => {
		if (data.sizes){
			for (var i=0; i<data.sizes.length; i++){
				var size = data.sizes[i];
				if (size[0]*1 !== 0){
					var row = view.getRow(size[0]);
					if(row) row.$height = size[2]*1;
				} else {
					var col = view.getColumn(size[1]);
					if(col) col.width = size[2]*1;
				}
			}
			if (data.sizes.length)
				view.refresh(true);
		}
	});

	
	view.attachEvent("onDataSerialize", (data) => {
		var sizes = [];
		var grid = view.$$("cells");
		var columns = grid.getState().order;
		var order = grid.data.order;

		var defWidth = grid.config.columnWidth;
		var defHeight = grid.config.rowHeight;

		for (let i = 1; i < columns.length; i++){
			var width = grid.getColumnConfig(columns[i]).width;
			if (width && width != defWidth)
				sizes.push([0, i, width]);
		}

		for (let i = 0; i < order.length; i++){
			var height = grid.getItem(order[i]).$height;
			if (height && height != defHeight)
				sizes.push([order[i]*1, 0, height]);
		}
		
		data.sizes = sizes;
	});

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});

	view.attachEvent("onBeforeStyleChange", function(row, column, n, o){
		if (o && o.props.wrap === "wrap" && (!n || n.props.wrap != o.props.wrap)){
			delete view._table.getItem(row).$height;
		}
	});
	view.attachEvent("onStyleChange", _adjustRowHeight);
	view.attachEvent("onCellChange",  _adjustRowHeight);
}

function _undoResize(view, row, column, value){
	if(row){
		view.$$("cells").getItem(row).$height = value;
		save(view, "sizes", { row:row, column: 0, size: value });
	}
	else{
		view._table.setColumnWidth(column, value);
	}
	// update area selection
	view._table.refreshSelectArea();
}

function updatePosition(name, inc, data, start){
	let i,
		sizes = data.sizes,
		nsizes = [];

	if(inc){
		for(i = sizes.length-1;i >=0;i--){
			let [row,column,size] = sizes[i];
			if(row && name == "row" && row>= start.row || column && name == "column" && column >= start.column){
				//moving row|column index
				row =  name == "row"?row*1 + inc:row;
				column = name == "column"?column*1 + inc:column;
				if((name == "row" && row>0) || (name == "column" && column >0))
					nsizes.push([row, column, size]);
			} else //row, column not affected by resize
				nsizes.push(sizes[i]);
		}
		data.sizes = nsizes;
	}
}

function _adjustRowHeight(row, column){
	const view = this;
	let style = getStyle(view, {row:row, column:column});

	//changing styles or content of wrapped cell -> resize the related row
	if (style && style.props.wrap){
		let item = view._table.getItem(row);
		let height = item.$height || view._table.config.rowHeight;

		view._table.eachColumn(function(col, config){
			let style = getStyle(view, {row:row, column:col});
			//include only cells with active wrap into calculation 
			if(style && style.props && style.props.wrap === "wrap"){
				let text = this.getText(row, col);
				height = Math.max(height, getTextSize(view, text, style.id, config.width).height);
			}
		});

		item.$height  = height;
	}
}
