import * as m1  from "./hide_gridlines";
import * as m2  from "./hide_headers";
import * as m3  from "./lock_cell";
import * as m4  from "./dropdown";
import * as m5  from "./filter";
import * as m6  from "./formats";
import * as m7  from "./hide_row_col";
import * as m8  from "./comments";

export function init(view){
	var modules = [m1,m2,m3,m4,m5,m6, m7, m8];
	view.attachEvent("onDataSerialize", function(data){
		for (var i = 0; i < modules.length; i++){
			if(modules[i].serialize)
				modules[i].serialize(view,data);
		}

	});
	view.attachEvent("onDataParse", function(data){
		view._loading_data = true;
		for (var i = 0; i < modules.length; i++){
			if(modules[i].load)
				modules[i].load(view,data);
		}
		view._loading_data = false;
	});
}