import * as sheets from "../sheets";
import * as spn from "../operations/spans";
import * as und from "../operations/undo";
import group from "../operations/undo_group";
import * as stl from "../operations/styles";
import * as srt from "../operations/sorting";
import {removeCommentsRange} from "../operations/comments";
import {clearConditionalFormats} from "../operations/conditional_formats";
import {clearEditors} from "../operations/dropdown";

//spans
export function combineCells(range){
	if(!range){
		var sel = this.getSelectedId(true);
		if (sel.length>1){
			range = spn.getRange(sel);
		}
	}
	group.set(()=> {
		if (range)
			spn.addSpan(this, range.cell, range.x, range.y);
	});
	this.refresh();
}

export function splitCell(row, column){
	if (row && column) {
		spn.removeSpan(this, {row: row, column: column});
	}
	else{
		group.set(function(){
			this.eachSelectedCell(function(cell){
				spn.removeSpan(this, cell);
			});
		},this);

	}

	this.refresh();
}

//styles
export function addStyle(prop, origin){
	return stl.addStyle(this, prop, origin);
}

export function getStyle(row, column){
	return stl.getStyle(this, {row:row, column:column});
}

export function setStyle(row, column, style){
	return stl.setStyle(this, {row: row, column: column}, style);
}

export function setRangeStyle(rangeStr, style){
	group.set(function() {
		stl.setRangeStyle(this, rangeStr, style);
	},this);
}

export function clearRange(rangeStr, type) {
	if (!rangeStr){
		rangeStr = this._table.getSelectArea();
		if (!rangeStr) return;
	}

	if (!type) type = {styles: true, values: true, editors:true, conditions:true, comments:true };
	
	if (type.styles)
		stl.clearRangeStyle(this,rangeStr);

	if (type.conditions)
		clearConditionalFormats(this,rangeStr);
	
	if (type.values)
		this.setRangeValue(rangeStr, null);

	if (type.editors)
		clearEditors(this, rangeStr);

	if (type.comments)
		removeCommentsRange(this, rangeStr);

	this.refresh();
}

export function compactStyles(){
	return stl.compactStyles(this);
}

// serialize
export function serialize(config){
	var obj = {};
	this.callEvent("onDataSerialize",[obj, config]);

	if (config && config.sheets)
		return sheets.serialize(this, obj);
	return obj;
}

//sheets
export function showSheet(name){
	sheets.show(this,name);
}
export function getActiveSheet(){
	return this._activeSheet;
}
export function addSheet(content){
	sheets.add(this, content);
}
export function clearSheet(){
	this.reset();
}
export function renameSheet(name,newName){
	sheets.rename(this,name,newName);
}
export function editSheet(name){
	sheets.edit(this,name);
}
export function removeSheet(name){
	sheets.remove(this,name);
}


//undo
export function undo(){
	und.undo(this);
}
export function redo(){
	und.redo(this);
}

// sort
export function sortRange(range, dir){
	srt.sortRange(this, range, dir);
}