import group from "./undo_group";
import {calculate} from "../math/math";
import {eachRange} from "../helpers/column_names";

export function init(view){
	view.conditions = {
		_empty:true,
		_pull: {},
		handlers: {
			">": function(a,b){
				return a > getCellValue(view, b);
			},
			"<": function(a,b){
				return a < getCellValue(view, b);
			},
			"=": function(a,b){
				return a == getCellValue(view, b);
			},
			"!=": function(a,b){
				return a != getCellValue(view, b);
			},
			"<>": function(a,b){
				var first = b[0];
				var second = b[1];
				return webix.isArray(b) && a < getCellValue(view, second) && a > getCellValue(view, first);
			}
		},
		add: add,
		remove: remove,
		update: update,
		get: get,
		parse: parse,
		serialize: serialize,
		clear: clear
	};

	view.attachEvent("onConditionSet", (data) => _setCondition(view, data));

	view.attachEvent("onUndo", (type_action, row, column, value) => {
		if(type_action == "condition")
			_undoCondition(view, row, column, value);
	});

	view.attachEvent("onDataSerialize", (data) => _serialize(view, data));
	view.attachEvent("onDataParse", (data) => _parse(view, data));

	view.attachEvent("onColumnInit", (column) => _setCssFormat(view, column));

	view.attachEvent("onReset", () => view.conditions.clear() );

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});

	reset(view);
}

function getCellValue(view, value){
	if(isNaN(value*1)){
		if (value.charAt(0) == "="){
			value = calculate(view, value);
		}
	}
	else {
		value = value*1;
	}
	return value;
}

function reset(view){
	view.conditions.clear();
}

function _setCondition(view, data){
	group.set(function(){
		view.eachSelectedCell((cell) => {
			var collection = view.conditions.get(cell.row,cell.column);
			_changeCondition("update", cell.row, cell.column, (collection||null), data, view);
		});
	});
	view.refresh();
}

function _undoCondition(view, row, column, value){
	if(view.conditions.get(row, column))
		view.conditions.remove(row, column);
	if(value)
		view.conditions.update(row, column, value);
}

function _serialize(view, obj){
	obj.conditions = view.conditions.serialize();
}

function _parse(view, obj){
	view.conditions.parse(obj.conditions);
}

function _setCssFormat(view,column){
	column.cssFormat = function (value, obj, row, column){
		if (view.conditions._empty) return "";

		var collection, i;
		collection = view.conditions.get(row, column);
		if(!collection)
			return "";

		i = collection.length;
		while(i--){
			var handler = view.conditions.handlers[collection[i][0]];
			if(handler && handler(obj[column],collection[i][1]))
				return collection[i][2];
		}
		return "";
	};
}


function addEmptyCollection(pull, row, column, state){
	state._empty = false;
	if(!pull[row])
		pull[row] = {};
	if(!pull[row][column])
		pull[row][column] = [];
	return pull[row][column];
}


function parse(data){
	this._empty = true;
	if (!data) return;

	let i = data.length;
	while(i--){
		var c = data[i];
		var collection = addEmptyCollection(this._pull,c[0],c[1],this);
		collection.push([c[2],c[3],c[4]]);
	}
}

function clear(){
	this._pull = {};
}

function update(row, column, newData){
	var collection = this.get(row, column);
	if(!collection)
		addEmptyCollection(this._pull, row, column, this);
	this._pull[row][column] = newData;
}

function get(rowId, columnId){
	if(!rowId)
		return this._pull;
	if(!columnId)
		return this._pull[rowId];
	return this._pull[rowId]?this._pull[rowId][columnId]:null;
}

function add(rowId, columnId, condition, value, style){
	var collection = addEmptyCollection(this._pull, rowId, columnId, this);
	collection.push([condition, value, style]);
}

function remove(rowId, columnId){
	var collection = this.get(rowId, columnId);
	if(collection)
		delete this._pull[rowId][columnId];
}

function serialize(){
	var column, condition, i, row,
		data = [];

	for( row in this._pull)
		for( column in this._pull[row])
			for(i=0; i < this._pull[row][column].length; i++){
				condition = this._pull[row][column][i];
				data.push([row,column, condition[0],condition[1],condition[2]]);
			}

	return data;
}

function updatePosition(name, inc, data, start){
	let conditions = data.conditions,
		i = conditions.length;

	if(inc){
		while(i--){
			let [row,column] = conditions[i];
			if(name == "row" && row>= start.row || name == "column" && column >= start.column){
				if (name == "row"){
					if (row < start.row - inc)	//delete lock mark if row was deleted
						conditions.splice(i,1);
					else						//update mark position if upper row was deleted
						conditions[i][0] = row*1 + inc;
				} else if (name == "column"){
					if (column < start.column - inc){
						conditions.splice(i, 1);
					} else 
						conditions[i][1] = column*1 + inc;
				}
			}
		}
	}
}

export function clearConditionalFormats(view, range){
	group.set(function(){
		eachRange(range, view, function(view, cell){
			var condition = view.conditions.get(cell.row, cell.column);
			if(condition)
				_changeCondition("remove", cell.row, cell.column, condition, null, view);				
		});
		view.refresh();
	});
}

export function pasteCondition(view, extra, row, col, cutted){
	var condition = extra.condition;
	var collection = view.conditions.get(row,col);
	_changeCondition("update", row, col, (collection||null), condition, view);
	if(cutted === 1)
		_changeCondition("remove", extra.row, extra.col, condition, null, view);	
			
}

export function _changeCondition(name, row, column, oldCondition, newCondition, view){
	if (view.callEvent("onBeforeConditionSet", [row, column, oldCondition, newCondition])){
		view.conditions[name].apply(view.conditions, [row, column, newCondition]);
		view.callEvent("onAfterConditionSet", [row, column, oldCondition, newCondition]);
	}
}