export function init(view){
	var holders = {};

	view.setPlaceholder = function(obj, value){
		if (typeof obj === "object")
			for (var key in obj)
				holders[key.toLowerCase()] = obj[key];
		else
			holders[obj.toString().toLowerCase()] = value;

		view.callEvent("onMathRefresh",[]);
	};

	return holders;
}