import * as undo from "../operations/undo";
import group from "../operations/undo_group";

export function resetUndo(){
	undo.reset(this);
}

export function groupUndo(func){
	group.set(func, this);
}

export function ignoreUndo(func){
	undo.ignoreUndo(func, this);
}