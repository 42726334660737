import * as numbers from "./methods/numbers";
import * as strings from "./methods/strings";
import * as other from "./methods/other";

export const methods = {};

webix.extend(methods, numbers);
webix.extend(methods, strings);
webix.extend(methods, other);

export function addMethod(name, handler){
	methods[name] = handler;
}