webix.protoUI({
	name:"multicheckbox",
	defaults:{
		padding:0,
		type:"clean",
		borderless:true,
		elementsConfig:{
			labelWidth:10
		}
	},
	$init:function(config){
		config.rows = [{height:0.000001}];
		this.$ready.push(function(){
			this._initOnChange();
		});
	},
	_initOnChange(){
		this.attachEvent("onChange", function(newv){
			const name = this.$eventSource.config.name;

			this.blockEvent();
			if(name == "$all"){
				for(let i in this.elements)
					this.elements[i].setValue(newv);
				if(!newv)
					this._getCheckboxes()[0].setValue(1);
			}
			else{
				const count = this.getValue().length;
				this.elements.$all.setValue(count == this._count ? 1 : 0);
				if(!count)
					this._getCheckboxes()[0].setValue(1);
			}
			this.unblockEvent();
		});
	},
	setValue:function(value){
		this.elements = {};
		this._count = value.length;

		if(value.length > 1){
			let elements = [];
			let base = [];
			const maxHeight = 400;

			value.forEach(sheet => {
				base.push({ view: "checkbox", labelRight: sheet.name, name: sheet.name, value: sheet.active});
			});

			const baseHeight = base.length * webix.skin.$active.inputHeight;
			const scroll = baseHeight > maxHeight;

			elements.push({
				view:"scrollview", body:{rows:base},
				scroll: scroll,
				height: scroll ? maxHeight : baseHeight
			});

			elements.push({ template:"<div class='ss_sep_line'></div>", height:10 });
			elements.push({ view:"checkbox", labelRight:webix.i18n.spreadsheet.labels["export-all-sheets"], name:"$all", value:0 });

			webix.ui(elements, this);
		}
		else if(this.getChildViews().length > 1){
			webix.ui([{height:0.000001}], this);
		}
	},
	getValue:function(){
		const checkboxes = this._getCheckboxes();
		let output = [];

		//save order
		for(let i = 0; i < checkboxes.length; i++)
			if(checkboxes[i].getValue())
				output.push(checkboxes[i].config.name);

		return output;
	},
	_getCheckboxes(){
		const scrollview = this.queryView("scrollview");
		return scrollview ? scrollview.getBody().getChildViews() : [];
	}
}, webix.ui.form);