webix.protoUI({
	name:"ssheet-icons",
	$cssName: "dataview",
	$init: function(){
		this.$view.className += " webix_ssheet_dataview";
	},
	defaults:{
		borderless: true,
		template: "<span class='webix_ssheet_button_icon #css#' ></span>",
		type:{
			width: 36,
			height: 36
		}
	}
},webix.ui.dataview);