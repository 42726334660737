function getText(text){
	var locale = webix.i18n.spreadsheet.labels;
	return locale[text] || text;
}
export function getDefaultStyles(){
	return [
		{name: getText("normal"), css: "webix_ssheet_condition_regular"},
		{name: getText("neutral"), css: "webix_ssheet_condition_neutral"},
		{name: getText("bad"), css: "webix_ssheet_condition_bad"},
		{name: getText("good"), css: "webix_ssheet_condition_good"}
	];
}