//global definitions
import "./locale";
import "./views/index";

//special content for non-production builds
import "./init";

//modules
import * as bar from "./toolbar";
import * as tbl from "./table";
import * as key from "./hotkeys";
import * as editor from "./editor";
import * as sheets from "./sheets";
import * as dialogs from "./dialogs/index";
import * as context from "./context";

import {setDefaultFormats} from "./operations/formats";
import {operations} from "./operations/index";
import {api} from "./api/index";

//styling
import * as menu from "./menu";
import {getDefaultStyles} from "./helpers/conditional";
import {setSkinDefaults} from "./toolbar/defaults";


var component = {
	name:"spreadsheet",
	_base_index:{ count:1 },
	defaults:{
		spans: true,
		rowCount:50,
		math:true,
		columnCount:20,
		resizeCell: true,
		sheetTabWidth: 90,
		conditionStyle: getDefaultStyles()
	},
	$init:function(){
		this.$index = (this._base_index.count++);
		this.$view.className += " webix_ssheet";

		this.$ready.unshift(this._sub_init);
		this.$ready.unshift(setDefaultFormats);
		this.$ready.push(this._set_handlers);
	},
	$skin:function(){
		setSkinDefaults();
	},
	_sub_init:function(){
		var obj = this.config;
		var rows = [];

		//toolbars
		if(!obj.readonly && obj.menu)
			rows.push(menu.init(this));
		if(!obj.readonly && obj.toolbar !== false)
			rows.push(bar.init(this));
		if (obj.subbar)
			rows.push(obj.subbar);
		if(obj.liveEditor)
			rows.push(editor.init(this));
		
		//data part
		let tblObj = {
			editable : !obj.readonly,
			clipboard : obj.clipboard,
			liveEditor: obj.liveEditor,
			clipboardDecimalDelimiter : obj.clipboardDecimalDelimiter
		};
		if(obj.rowHeight)
			tblObj.rowHeight = obj.rowHeight;
		if(obj.columnWidth)
			tblObj.columnWidth = obj.columnWidth;

		rows.push(tbl.init(this, tblObj));

		var bottom = obj.bottombar === true ? sheets.defaultBottom(this) : obj.bottombar;
		if (bottom)
			rows.push(bottom);

		sheets.init(this);

		if(!obj.readonly)
			context.init(this);

		dialogs.init(this);

		this.rows_setter(rows);
	},
	_set_handlers:function(){
		//prevent double init
		if (this._table) return;
		this._table = this.$$("cells");

		//init all sub modules
		operations(this);

		//set sizes for the table
		tbl.reset(this, this.config.columnCount, this.config.rowCount);
		key.init(this); //keyboard shortcuts
		
		this._table.attachEvent("onAfterAreaAdd", () =>
			this.callEvent("onAfterSelect", [ this.getSelectedId(true) ]));

		this.callEvent("onComponentInit", []);
	},
	$onLoad:function(obj, driver){
		//when loading data by data:, we can get $onLoad before _set_handlers call
		this._set_handlers();

		if (obj.excel)
			obj = this._parseExcel(obj, driver);
		else if(!obj.data && typeof obj == "string" && (driver.cell || driver.row))
			obj = this._parseCsv(obj, driver);

		sheets.load(this, obj);
	}
};
api(component);
webix.protoUI(component, webix.AtomDataLoader, webix.IdSpace, webix.ui.layout);
