import {Dialog} from "./common";
import {isRange, toSheetRange} from "../helpers/column_names";

export const action = "add-range";

export class DialogBox extends Dialog{
	$show(box){
		var table = box.$$("table");
		var form = box.$$("form");
		var sheet = "";// this.view.getActiveSheet(); ranges are per-sheet by default

		form.clear();
		form.elements.name.focus();
		form.elements.range.setValue(this.view.getSelectedRange());

		table.clearAll();
		table.parse( this.view.ranges.getRanges() );

		this.view.$handleSelection = function(st, end, a, b){
			form.elements.range.setValue(toSheetRange(a,b,sheet));
			return false;
		};
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	saveClick(){
		var form = this.$dialog.$$("form");
		var data = form.getValues();

		var sheets = data.range.indexOf("!");
		data.range = data.range.substr(0, sheets+1)+data.range.substr(sheets+1).toUpperCase();
		data.name = data.name.toUpperCase();

		form.setValues(data);

		if (form.validate()){
			let table = this.$dialog.$$("table");

			if (data.id && table.exists(data.id)){				
				this.view.ranges.remove(table.getItem(data.id).name);
				table.updateItem(data.id, data);
			} else
				this.$dialog.$$("table").add(data);

			this.view.ranges.add(data.name, data.range);
			form.clear();
		}
	}
	removeRange(id){
		this.view.confirm({
			text: webix.i18n.spreadsheet.labels["range-remove-confirm"],
			callback:(res) => {
				if (res){
					let table = this.$dialog.$$("table");
					this.view.ranges.remove(table.getItem(id).name);
					table.remove(id);
				}
			}
		});
	}
	editRange(id){
		var form = this.$dialog.$$("form");
		form.setValues(this.$dialog.$$("table").getItem(id));
	}
	$init(){
		var theform = {
			type: "clean",
			cols:[
				{ view: "ssheet-dialog-table", id: "table", borderless:true, columns:[
					{ id:"name", header:webix.i18n.spreadsheet.labels["range-name"], width: 120 },
					{ id:"range", header:webix.i18n.spreadsheet.labels["range-cells"], width: 120 },
					{ template:"<div class='webix_icon wxi-pencil'></div>", width:40 },
					{ template:"<div class='webix_icon wxi-trash'></div>", width:40 }
				],
				autowidth:true, height: 150,
				onClick:{
					"wxi-trash":(ev, id) => this.removeRange(id),
					"wxi-pencil":(ev, id) => this.editRange(id)
				}},
				{ width: 250, view: "form", id:"form",
					rules:{
						name:(value) => { 
							let correct = /^[A-Za-z]+$/.test(value);
							let table = this.$dialog.$$("table");
							let data = this.$dialog.$$("form").getValues();
							
							let unique = true;
							table.eachRow(function(id){
								let obj = this.getItem(id);
								if (obj.name == value && obj.id != data.id)
									unique = false; 
							});
							
							return correct && unique; 
						},
						range:isRange
					},
					elementsConfig:{
						labelWidth: 70
					},
					elements:[
						{ view: "text", name:"name", gravity:1, label: webix.i18n.spreadsheet.labels["range-name"]},
						{ view: "text", name:"range", gravity:1, label: webix.i18n.spreadsheet.labels["range-cells"]},
						{ cols:[
							{},
							{ view:"button", value:"Save", click: () => this.saveClick() }
						]}
					]
				}
			]
		};

		return {
			view: "ssheet-dialog",
			move:true,
			head: webix.i18n.spreadsheet.labels["range-title"],
			buttons: false,
			autoheight: true,
			width: 610,
			position: "center",
			body: theform,
			on:{
				onSaveClick: () => this.close(),
				onHideClick: () => this.close(),
				onCancelClick: () => this.close()
			}
		};
	}
}

