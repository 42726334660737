export function find(arr, func){
	var result = findIndex(arr, func);
	return arr[result];
}

export function findIndex(arr, func){
	var result = -1;
	for(var i =0; result<0 && i < arr.length; i++){
		if(func(arr[i]))
			result = i;
	}
	return result;
}

export function getDimension(data, rows, cols){
	//var [ row, column, value, style] = obj.data[i];
	for (var i=0; i<data.length; i++){
		let line = data[i];
		if (line[0]>rows) rows=line[0];
		if (line[1]>cols) cols=line[1];
	}
	return [rows*1, cols*1];
}