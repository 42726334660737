import * as rco from "./row_col_operations";
import * as lcl from "./lock_cell";
import * as fcl from "./freeze";
import * as hbr from "./hide_gridlines";
import * as hhd from "./hide_headers";
import * as hrc from "./hide_rows";
import * as mat from "./math";
import * as ops from "./operations";
import * as cor from "./core";
import * as sav from "./save";
import * as dpd from "./dropdown";
import * as cfm from "./confirm";
import * as und from "./undo";
import * as fmt from "./number_formats";

export function api(view){
	var all = [rco, lcl, fcl, hbr, hhd, hrc, mat, ops, cor, sav, dpd, cfm, und, fmt];
	for (var i = 0; i < all.length; i++)
		webix.extend(view, all[i]);
}