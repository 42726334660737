export function init(view){
	const events = [
		{name:"onSheetAdd", type:"insert"},
		{name:"onSheetRemove", type:"remove"},
		{name:"onSheetRename", type:"rename"},
		{name:"onCellChange"},
		{name:"onStyleChange"},
		{name:"onAction"},
		{name:"onRowOperation"},
		{name:"onColumnOperation"},
		{name:"onAfterConditionSet"},
		{name:"onAfterRangeSet"},
		{name:"onAfterSpan"},
		{name:"onAfterSplit"},
		{name:"onRowResize", view: "cells"},
		{name:"onColumnResize", view: "cells"}
	];

	let thread;
	for (let i = 0; i < events.length; i++){
		const handler = events[i];
		(handler.view ? view.$$(handler.view) : view).attachEvent(handler.name, function(name, newName){
			if (view._loading_data) return;

			let params;
			if(handler.type)
				params = handler.type == "rename" ? [handler.type, newName, name] : [handler.type, name];
			else
				params = ["update", view.getActiveSheet()];

			clearTimeout(thread);
			thread = webix.delay(() => view.callEvent("onChange", params));
		});
	}

	view.attachEvent("onChange", function(){
		save(view, "all");
	});
}

export function save(view, name, data){
	var save = view.config.save;
	var url;
	if (!save) return;

	if(name == "all"){
		if(!save.all)
			return;
		data = { data: view.serialize({ sheets: true }) };
		url = save.all;
	}
	else{
		url = typeof save === "string" ? save + "/" + name : save[name];
	}

	url = webix.proxy.$parse(url);

	if(url){
		if (typeof url === "function")
			return url(name, data);

		if (url.$proxy && url.save)
			return url.save(view, data, null, null);

		var ajax = webix.ajax();
		if (name === "all")
			ajax.headers({ "Content-type" : "application/json" });
		ajax.post(url, data);
	}
}