import group from "./undo_group";
import {eachRange} from "../helpers/column_names";

export function init(view){
	view.comments = {
		get: function(row, column){
			return getComment(view, row, column);
		},
		add: function(row, column, comment){
			commentCell(view, row, column, comment);
		},
		remove: function(row, column){
			removeComment(view, row, column);
		},
		_activeComment: {},
		_pull:{}
	};

	view.attachEvent("onReset", () => reset(view));
	view.attachEvent("onUndo", (type, row, column, value) => {
		if (type === "comment")
			_undoComment(view, row, column, value);
	});

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});
}

function _undoComment(view, row, column, value){
	if(getComment(view, row, column))
		removeComment(view, row, column, true);
	if(value)
		commentCell(view, row, column, value, true);
}

function reset(view){
	view.comments._pull = {};
}

function removeComment(view, row, column, silent){
	if(getComment(view, row, column)){
		if (!silent)
			view.callEvent("onAction", ["comment", {row, column, newValue: null, value: view.comments._pull[row][column]}]);
		delete view.comments._pull[row][column];
	}
	view.$$("cells").updateItem(row,column);
}

export function removeCommentsRange(view, range){
	group.set(function(){
		eachRange(range, view, function(view, cell){
			if(getComment(view, cell.row, cell.column))
				removeComment(view, cell.row, cell.column);
		});
		view.$$("cells").refresh();
	});
}

function commentCell(view, row, column, comment, silent){
	var oldValue = getComment(view, row, column) ? view.comments._pull[row][column] : null;
	var table = view.$$("cells");

	if(typeof comment == "string")
		comment = comment.trim() ? comment : "";
	view.comments._pull[row] = view.comments._pull[row] ? view.comments._pull[row] : {};
	view.comments._pull[row][column] = comment;

	table.addCellCss(row, column, "ssheet_commented_cell");

	if (!silent)
		view.callEvent("onAction", ["comment", {row, column, newValue:comment, value:oldValue}]);
}

export function serialize(view,data){
	var row, column,
		comments = [];
	for(row in view.comments._pull){
		for(column in view.comments._pull[row])
			if(view.comments._pull[row][column])
				comments.push([row, column, view.comments._pull[row][column]]);
	}
	data.comments = comments;
}

export function load(view,data){
	var i,
		comments = data.comments;

	if(comments)
		for(i = 0; i < comments.length; i++)
			commentCell(view, comments[i][0], comments[i][1], comments[i][2], true);
}

function updatePosition(name, inc, data, start){
	let comments = data.comments,
		i = comments.length;

	if(inc){
		while(i--){
			let [row,column] = comments[i];
			if(row && name == "row" && row>= start.row || column && name == "column" && column >= start.column){
				if (name == "row"){
					if (row < start.row - inc)	//delete comment if row was deleted
						comments.splice(i,1);
					else						//update mark position if upper row was deleted
						comments[i][0] = row*1 + inc;
				} else if (name == "column"){
					if (column < start.column - inc){
						comments.splice(i, 1);
					} else 
						comments[i][1] = column*1 + inc;
				}
			}
		}
	}
}

function getComment(view, row, column){
	return view.comments._pull[row] && view.comments._pull[row][column] ? view.comments._pull[row][column] : "";
}