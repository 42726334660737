import * as parser 			from "./parser";

//convert math string to the js function
let actives = {};
let passives = {};
export function _parse(value, core, passive){
	var struct = parser.parse(value, core, passive);
	var cache = passive ? passives : actives;
	struct.handler = cache[value] = cache[value] || _build_function(struct.code);
	return struct;
}

export function clearMathCache(){
	actives = {};
	passives = {};
}

function error_function(){
	return webix.i18n.spreadsheet.table["math-error"];
}
function _build_function(value){
	try{
		return new Function(value);
	} catch(e){
		return error_function;
	}
}