import {addMethod} from "../math/methods";
import {image, chart} from "../math/writer";
import {getAccessor} from "../math/sheet";

export function addImage(row, column, url){
	this.setCellValue(row, column, image(url));
	this.refresh();
}

export function addSparkline(row, column, config){
	let value = chart(config);
	this.setCellValue(row, column, value);
	this.refresh();
}

export function getSheetData(name){
	return getAccessor(this, name);
}

export function recalculate(){
	this.callEvent("onMathRefresh",[]);
}

export function registerMathMethod(name, handler){
	name = name.toUpperCase();
	addMethod(name, handler);
	if(this.$$("liveEditor")){
		const sid = this.$$("liveEditor").config.suggest;
		const list = webix.$$(sid).getList();
		if(!list.exists(name))
			list.add({id:name, value:name});
	}
}