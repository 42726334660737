import "../views/formlate";

export class Dialog{
	constructor(view){
		this.view = view;
	}
	$init(){}
	$show(){}
	$hide(){}
	open(){
		if(!this.$dialog){
			this.$dialog = webix.ui(this.$init());
			this.$dialog.attachEvent("onHide", ()=>this.$hide());
		}
			
		var form = this.$dialog.getBody();
		this.$dialog.show();
		if (this.$show(this.$dialog, form) === false)
			this.close();
	}
	close(){
		webix.UIManager.setFocus(this.view);
		this.$dialog.hide();
	}
}