webix.protoUI({
	name: "ssheet-button",
	$cssName: "button",
	defaults:{
		type:"htmlbutton",
		width: 40
	},
	$init: function(config){
		this.$view.className += " webix_ssheet_button"+(config.arrow?" webix_ssheet_button_menu":"");
		if(config.label || config.icon){
			let icon = (config.icon||config.name),
				text = config.label||"";
			if(config.arrow)
				text += "<span class='webix_ssheet_button_icon webix_ssheet_icon_arrow webix_icon wxi-menu-down'></span>";
			config.label = "<span class='webix_ssheet_button_icon webix_ssheet_icon ssi-"+icon+"'></span> "+text;
			config.tooltip = webix.i18n.spreadsheet.tooltips[config.name]||"";
		}
		if(config.options && !config.popup)
			config.popup = getPopupConfig(config.options);
	}
},webix.ui.button);


webix.protoUI({
	name: "ssheet-button-icon-top",
	$cssName: "button",
	defaults:{
		type:"htmlbutton",
		width: 70,
		height: 70
	},
	$init: function(config){
		this.$view.className += " ssheet_button_icon_top";
		if(config.label) {
			let icon = (config.icon || config.name||""),
				text = config.label;
			config.label = "<span class='webix_ssheet_button_icon webix_ssheet_icon ssi-" + icon + "'></span><br/>";
			config.label += "<span class='ssheet_button_icon_top_text'>"+text+"</span>";
			if (config.arrow)
				config.label += "<br/><span class='ssheet_button_icon_top_arrow webix_icon wxi-menu-down'></span>";
			config.tooltip = webix.i18n.spreadsheet.tooltips[config.name]||"";
		}
		if(config.options && !config.popup)
			config.popup = getPopupConfig(config.options);
	}
},webix.ui.button);


function getPopupConfig(options){
	return {
		view: "ssheet-suggest",
		css: "webix_ssheet_suggest",
		autowidth:true,
		template: function(obj){
			var text = "";
			if(obj.icon)
				text += "<span class='webix_ssheet_button_icon webix_ssheet_icon ssi-" + obj.icon + "'></span> ";
			text += obj.value || webix.i18n.spreadsheet.menus[obj.id] || obj.id;
			return text;
		},
		data: options,
		on:{
			onItemClick: function(id){
				var button = this.config.master;
				if(button){
					let view = webix.$$(button).getTopParentView();
					var params = [this.getItem(id)];
					if(webix.$$(button).config.area){
						let area = view.$$("cells").getSelectArea();
						if(area)
							params = params.concat([area.start,area.end]);
					}
					view.callEvent("onCommand",params);
				}
			}
		}
	};
}