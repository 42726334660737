import * as hrows from "../operations/hide_row_col";

export function hideColumn(column,state){
	hrows.setState(this, column, state, "column");
}
export function isColumnVisible(column){
	return hrows.isColumnVisible(this, column);
}
export function hideRow(row,state){
	hrows.setState(this, row, state, "row");
}
export function isRowVisible(row){
	return hrows.isRowVisible(this, row);
}