import group from "./undo_group";

export const lockCss = "webix_lock";

export function init(view){
	view._locks = {};
	view.attachEvent("onReset", () => reset(view));
	view.attachEvent("onUndo", (type, row, column, mode) => {
		if (type === "lock") lockCell(view, row, column, !!mode, true);
	});

	view.attachEvent("onCommand", (cm) => {
		if (cm.id === "lock-cell")
			lock(view);
	});

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});
}

function reset(view){
	view._locks = {};
}

export function lockCell(view, row, column, mode, silent){
	var table = view.$$("cells");
	if (typeof row === "object" && typeof column === "object"){
		group.set(function() {
			_mapCellsCalls(row, column, function(row, column){
				lockCell(view, row, column, mode, true);
			});
		});
	} else {
		mode = ((mode === false) ? false : true);
		var objrow = view._locks[row] = view._locks[row] || {};
		objrow[column] = mode;
		if (mode)
			table.addCellCss(row, column, lockCss, true);
		else
			table.removeCellCss(row, column, lockCss, true);

		view.callEvent("onAction", ["lock", { row, column, value: !mode, newValue: mode }]);
	}

	if (!silent)
		table.refresh();
}

function _mapCellsCalls(start, end, handler,master){
	for (let x = start.row; x<=end.row; x++)
		for (let y = start.column; y<=end.column; y++)
			handler.call(master||this, x, y);
}

export function isCellLocked(view, row, column){
	return view._locks[row]?view._locks[row][column]:false;
}

export function serialize(view,data){
	var row, column,
		locked = [];
	for(row in view._locks){
		for(column in view._locks[row])
			if(view._locks[row][column])
				locked.push([row,column]);
	}
	data.locked = locked;
}

export function load(view,data){
	var i,
		locked = data.locked;

	if(locked)
		for(i = 0; i < locked.length; i++)
			lockCell(view, locked[i][0],locked[i][1],true, true);
}

function lock(view){
	var block = view.$$("cells").getSelectArea();
	if (block){
		var mode = view.isCellLocked(block.start.row, block.start.column);
		view.lockCell(block.start, block.end, !mode);
	}
}

function updatePosition(name, inc, data, start){
	let locked = data.locked,
		i = locked.length;

	if(inc){
		while(i--){
			let [row,column] = locked[i];
			if(row && name == "row" && row>= start.row || column && name == "column" && column >= start.column){
				if (name == "row"){
					if (row < start.row - inc)	//delete lock mark if row was deleted
						locked.splice(i,1);
					else						//update mark position if upper row was deleted
						locked[i][0] = row*1 + inc;
				} else if (name == "column"){
					if (column < start.column - inc){
						locked.splice(i, 1);
					} else 
						locked[i][1] = column*1 + inc;
				}
			}
		}
	}
}