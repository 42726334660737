export function confirm(config){
	webix.modalbox({
		type: "alert webix_ssheet_confirm",
		text:config.text,
		buttons:[webix.i18n.spreadsheet.labels.cancel,webix.i18n.spreadsheet.labels.ok],
		callback:(result) => {
			config.callback.call(this,result!="0");
		}
	});
}

export function alert(config){
	webix.modalbox({
		type: "alert webix_ssheet_alert",
		text:config.text,
		buttons:[webix.i18n.spreadsheet.labels.ok]
	});
}