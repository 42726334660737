export function image(url){
	return `=IMAGE("${url}")`;
}

export function chart(config){
	let result = `=SPARKLINE(${config.range},"${config.type}"`;
	if (config.type === "bar")
		result += `,"${config.color}","${config.negativeColor}"`;
	else if(config.color)
		result += `,"${config.color}"`;
	return result+")";

}

export function parseImage(value){
	if (value && value.indexOf("=IMAGE(") === 0)
		return {
			url: unescape(value.substr(7, value.length-8))
		};
}

export function parseChart(value){
	if (value && value.indexOf("=SPARKLINE(") === 0){
		let text = value.substr(11, value.length-12).split(",");
		return {
			range: text[0],
			type: unescape(text[1]),
			color: unescape(text[2]),
			negativeColor: unescape(text[3])
		};
	}
}

function unescape(text){
	if (!text) return "";

	text = text.trim();
	if (text[0] === "\"") 
		text = text.substr(1);
	if (text[text.length-1] === "\"")
		text = text.substr(0, text.length-1);
	return text;
}