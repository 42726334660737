import group from "../operations/undo_group";

export function freezeColumns(column){
	const grid = this.$$("cells");
	
	if( webix.isUndefined(column) ){
		let sel = this.getSelectedId(true);
		column = sel.length?sel[0].column:0;
	}

	const base = grid.config.columns[0].id == "rowId" ? 1 : 0;
	let index = base;
	if (column && column != this._frozenColumns)
		index = grid.getColumnIndex(column*1+1);
	if (index<base) index = base;

	group.set(function(){
		checkSpans(this, "column", column);
		grid.unselect();
		grid.define("leftSplit", index);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-column", { value: (this._frozenColumns || 0), newValue: column}]);
		this._frozenColumns = index > base ? column : 0;
	},this);
}

export function freezeRows(row){
	const grid = this.$$("cells");

	if( webix.isUndefined(row) ){
		let sel = this.getSelectedId(true);
		row = sel.length?sel[0].row:0;
	}

	let index = 0;
	if (row && row != this._frozenRows)
		index = grid.getIndexById(row*1+1);
	if (index < 0) index = 0;

	group.set(function(){
		checkSpans(this, "row", row);
		grid.unselect();
		grid.define("topSplit", index);
		grid.refreshColumns();

		this.callEvent("onAction", [ "freeze-row", { value: this._frozenRows, newValue: row}]);
		this._frozenRows = index ? row : 0;
	},this);
}

function checkSpans(view, type, num){
	const grid = view.$$("cells");

	if(type == "row"){
		let start = 1;
		let end = view.config.columnCount;
		for(let i = start; i <= end; i++){
			const span = grid.getSpan(num, i);
			if(span){
				const spanSize = (span[0]*1) + span[3] - 1;
				if(spanSize>num)
					changeSpan(view, span, span[2], num-span[1]+1);
			}
		}
	}
	else{//column
		let start = grid.data.order[0];
		let end = grid.data.order.length;
		for(let i = start; i <= end; i++){
			const span = grid.getSpan(i, num);
			if(span){
				const spanSize = (span[1]*1) + span[2] - 1;
				if(spanSize>num)
					changeSpan(view, span, num-span[0]+1, span[3]);
			}
		}
	}
}

function changeSpan(view, span, x, y){
	view.splitCell(span[0], span[1]);
	view.combineCells({
		cell:{
			row:span[0],
			column:span[1]
		},
		x:x,
		y:y
	});
}