import * as methods from "./math/methods";

let methodsName = [];
for(let i in methods.methods)
	if( i!="__esModule") methodsName.push(i);

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	return {
		view:"toolbar",
		css:"webix_ssheet_toolbar",
		elements:[
			{ view:"label", label:"Edit: ", width:60},
			{
				view:"live-editor",
				disabled:true,
				id:"liveEditor", 
				suggestData: methodsName
			}
		]
	};
}

function ready(view){
	var editor = view.$$("liveEditor");

	//block native editor, and move focus to the custom input
	//edit by key press
	view._table.attachEvent("onBeforeEditStart", function(cell){
		let mode = view._table.$anyKey;
		view._table.$anyKey = false;

		return startEdit(this, cell, mode);
	});
	//edit by enter key
	view._table.attachEvent("onBeforeLiveEditor", function(cell){
		startEdit(this, cell, false);
	});
	//edit by dbl-click
	view._table.attachEvent("onItemDblClick", function(){
		webix.delay(()=>{
			editor.focus();
		});
	});

	view._table.attachEvent("onAfterScroll", function(){
		if(editor.$view.contains(document.activeElement))
			editor.paintValue();
	});

	view.attachEvent("onCellChange", (r,c,v) => {
		var cell = editor.config.activeCell;
		if (cell && cell.row == r && cell.column == c)
			editor.setValue(v);
	});

	view.attachEvent("onAfterSelect", (data) => {
		if (!view.$handleSelection){
			let cell = data[0];
			fillEditor(cell);
		}
	});

	view.attachEvent("onBeforeSheetShow", (name)=> {
		const cell = editor.config.activeCell;
		if(cell){
			const val = editor.getValue();
			if(view.getCellValue(cell.row, cell.column) != val){
				if(!editor._activeMath)
					editor._activeMath = view.getActiveSheet();

				webix.delay(()=>{
					if(name == editor._activeMath){
						view.$handleSelection = null;
						const cell = editor.config.activeCell;
						view._table.select(cell.row, cell.column);
					}

					editor.setValue(val);
					editor.setCursor(editor._cursor);
					editor.paintValue();

					view.$handleSelection = function(a,b,st,en){ return pasteRange(st, en, a); };
				});
			}
			else{
				editor.define({activeCell:null});
				editor.setValue("");
				editor.disable();
			}
		}
	});

	view.attachEvent("onReset", () => view.$handleSelection = null);

	view.attachEvent("onAction", function(name, options){
		if(name == "lock" || name == "filter" || name == "dropdown")
			disableEditor(options.row,  options.column);
	});

	function startEdit(table, cell, clear){
		var cellEditor = table.getColumnConfig(cell.column).editor;
		//do not interfere with custom editors
		if (cellEditor == "text"){
			fillEditor(cell, clear);
			editor.focus();
			if(!clear)
				editor.paintValue();
			webix.delay(()=>{
				editor._cursor = editor.getCursor();
			});
			return false;
		}
		return true;
	}

	function disableEditor(row,column){
		if (view.getCellEditor(row, column) || view.isCellLocked(row,column)){
			editor.setValue("");
			editor.disable();
			return true;
		}
		return false;
	}

	function fillEditor(cell, clear){
		if(disableEditor(cell.row, cell.column))
			return;
		editor.enable();
		editor.config.activeCell = cell;
		editor.setValue( clear ? "" : view.getCellValue(cell.row, cell.column) );

		view.$handleSelection = function(a,b,st,en){ return pasteRange(st, en, a); };
	}

	function pasteRange(st, en, cell){
		let sheet = "";
		if(editor._activeMath){
			const activeSheet = view.getActiveSheet();
			if(editor._activeMath != activeSheet)
				sheet = editor.prepareSheet(activeSheet);
		}

		const range = st == en ? sheet+st : `${sheet+st}:${sheet+en}`;
		const updateRange = editor._update_range;

		if(updateRange && updateRange.pos == editor._cursor - updateRange.len)
			editor.setRange(range, true);
		else if(editor.expectOperator())
			editor.setRange(range);
		else
			return endEdit(cell);

		webix.delay(() => editor.focus());

		return false;
	}

	function endEdit(st){
		if (editor.isEnabled()){
			editor.showActiveSheet();
			editor.updateCellValue();
			editor.setValue(st ? view.getCellValue(st.row, st.column) : "");
		}
		return true;
	}

}