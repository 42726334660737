import {Dialog} from "./common";
export const action = "resize";

export class DialogBox extends Dialog{
	open(action){
		this.type = action.group;
		this.header = action.value;
		Dialog.prototype.open.apply(this, arguments);
	}
	$show(){
		const sel = this.view.getSelectedId();
		if(!sel)
			return false;
		this.view.$handleSelection = ()=>{
			return false;
		};
		this.size = this.$dialog.getBody().elements["size"];
		this.$dialog.getHead().getChildViews()[0].setHTML(this.header);
		const grid = this.view.$$("cells");

		let value;
		if(this.type == "row")
			value = grid.getItem(sel.row).$height || grid.config.rowHeight;
		else
			value = grid.getColumnConfig(sel.column).width || grid.config.columnWidth;

		this.size.define({ value: value, label: webix.i18n.spreadsheet.labels[this.type == "row" ? "height" : "width"]});
		this.size.refresh();
		this.size.focus();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	okClick(){
		const size = this.size.getValue()*1;
		if(this.type == "row")
			this.view.setRowHeight(null, size);
		else
			this.view.setColumnWidth(null, size);
		this.close();
	}
	$init(){
		return {
			view: "ssheet-dialog",
			position:"center",
			width: 300,
			move: true,
			body:{
				name: "size",
				view: "text"
			},
			on:{
				onSaveClick: () => this.okClick(),
				onHideClick: () => this.close(),
				onCancelClick: () => this.close()
			}
		};
	}
}