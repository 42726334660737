export function CONCATENATE(){
	var data = Array.prototype.slice.call(arguments);
	data = data.map(function(obj){
		if (typeof obj === "object")
			return obj.join("");
		return obj;
	});
	return data.join("");
}
 
export function LEFT(text, count) {
	if (!text) { return ""; }
	return text.substring(0, count);
}

export function MID(text, start, count) {
	if (!text) { return ""; }
	return text.substring(start, start + count);
}

export function RIGHT(text, count) {
	if (!text) { return ""; }
	return text.substring(text.length - count);
}

export function LOWER(text) {
	if (!text) { return ""; }
	return text.toLowerCase();
}

export function UPPER(text) {
	if (!text) { return ""; }
	return text.toUpperCase();
}

export function PROPER(text) {
	if (!text) { return ""; }
	var temp = text.toLowerCase().split(" ");
	text = "";
	for (var idx in temp) {
		text += (text ? " " : "") + temp[idx].substring(0, 1).toUpperCase() + temp[idx].substring(1);
	}
	return text;
}

export function TRIM(text) {
	if (!text) { return ""; }
	return text.trim();
}

export function LEN(text) {
	if (!text && text !== 0) { return 0; }
	return text.toString().length;
}