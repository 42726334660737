import group from "../operations/undo_group";

export function init(view){
	view.attachEvent("onCommand", (action) => {
		switch (action.id) {
			case "clear-value":
				view.clearRange(null,{values: true});
				break;
			case "clear-style":
				view.clearRange(null,{styles: true});
				break;
			case "clear-conditional-formats":
				view.clearRange(null,{conditions: true});
				break;
			case "clear-dropdown-editors":
				view.clearRange(null,{editors: true});
				break;
			case "clear-all":
				group.set(function(){
					view.clearRange(null,null);
				});
				break;
			case "clear-comments":
				view.clearRange(null,{comments: true});
				break;
		}
	});
}