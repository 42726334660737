export function print(view, options){
	let active = view._activeSheet;

	let html = _buildHtml(view, options, active);
	webix.html.insertBefore(html, options.docFooter, document.body);
	window.print();
	_cleanHtml(view, html);

	view.showSheet(active);
}

export function init(view){
	view.$customPrint = function(options){
		print(view, options);
	};
}

function _cleanHtml(view, html){
	webix.html.remove(html);

	view._sheets.forEach((sheet, i) => {
		webix.html.removeStyle(".wss_"+view.$index+"_"+i);
	});
}
function _buildHtml(view, options, active){
	let doc = webix.html.create("div", { "class":"webix_ssheet_print"});

	options.xCorrection = 1;
	options.header = options.header || false;
	options.trim = webix.isUndefined(options.trim) ? true : options.trim;
	options.sheetnames = webix.isUndefined(options.sheetnames) ? true : options.sheetnames;
	const baseIndex = view.$index;

	view._sheets.forEach((sheet, i) => {
		view.$index = baseIndex+"_"+i;
		if(options.data ==="all" || (options.data !== "all" && sheet.name === active)){
			view.showSheet(sheet.name);
			let prefix = "wss_"+view.$index;

			if(options.sheetnames){
				let sheetname = webix.html.create("div", { "class":"webix_view webix_ssheet_sheetname"}, sheet.name+":");
				doc.appendChild(sheetname);
			}

			let table = view._table.$customPrint(options, true);

			//for empty sheet we don't have any HTML
			if (table.firstChild)
				table.firstChild.className += " "+prefix;
			doc.appendChild(table);
			
			if(options.data == "all" && i+1 < view._sheets.length){
				var br = webix.html.create("DIV", {"class":"webix_print_pagebreak"});
				doc.appendChild(br);
			}
		}
	});
	view.$index = baseIndex;
	
	return doc;
}