import "./suggest_formula";
import {split, position} from "../math/parser";

webix.protoUI({
	name: "live-editor",
	defaults:{
		template: "<div class='webix_ssheet_formula_input' contenteditable='true'></div>"
	},
	$init(config){
		this._highlightedCells = {};
		this.$view.className += " webix_ssheet_formula";

		this.attachEvent("onKeyPress", (code, e) => {
			delete this._update_range;
			if(code === 13) {
				//ignore Enter key if it was pressed to select some value from suggest
				if ((new Date() - (this._last_value_set || 0)) > 300){
					this.showActiveSheet();
					this.updateCellValue();
					//we have 'enter' hot key to activate editor
					webix.delay(()=>{
						this.getTopParentView()._table.moveSelection("down");
					});
				}
				e.preventDefault();
			}
		});

		this.attachEvent("onAfterRender", function(){
			var node = this.getInputNode();
			var ev =  webix.env.isIE8?"propertychange":"input";

			webix.eventRemove(this.inputEv);
			webix.eventRemove(this.keydownEv);

			this.inputEv = webix.event(node, ev, () => {
				this._cursor = this.getCursor();
				//we can start typing on colored span, so we should update input highlight
				this.highlightCells(this.getValue());
				this.setCursor(this._cursor);
			});
			this.keydownEv = webix.event(node, "keydown", e => {
				this.endEdit(e);
			});

			this.config.suggest = webix.ui({
				view: "suggest-formula",
				input: this.getInputNode(),
				editor:this,
				data: config.suggestData
			});
		});
	},
	showActiveSheet(){
		const master = this.getTopParentView();
		delete master.$handleSelection;

		if(this._activeMath){
			if(this._activeMath != master.getActiveSheet()){
				const val = this.getValue();

				const cell = this.config.activeCell;
				this.define({activeCell:null});

				master.showSheet(this._activeMath);

				webix.delay(()=>{
					master._table.select(cell.row, cell.column);
					this.setValue(val);
					this.updateCellValue();
				});
			}
			delete this._activeMath;
		}
	},
	getInputNode(){
		return this.$view.firstChild.firstChild;
	},
	getValue(){
		return this.getInputNode().innerText;
	},
	setValue(value){
		this.highlightCells(value);
	},
	highlightCells(value){
		let html = "";
		const master = this.getTopParentView();
		const escape = webix.template.escape;

		for(let area in this._highlightedCells){
			const cells = this._highlightedCells[area];
			this.changeCellCss(cells[0], cells[1], cells[2], true);
		}
		this._highlightedCells = {};

		//value can be null
		if(value && value[0]=="="){
			const ranges = master.ranges._ranges;
			const parts = split(value, true, ranges);
			const sheet = master.getActiveSheet();

			let colorIndex = 1;

			for(let i = 0; i < parts.length; i++){
				if((i+1)%2 != 0)
					html += escape(parts[i]);
				else{
					const crossheet = webix.isArray(parts[i]);
					const cellSheet = crossheet ? this.prepareSheet(parts[i][0]) : "";
					const activeMath = this._activeMath;

					const activeSheet = !crossheet && (!activeMath || activeMath == sheet);
					let cell = position(crossheet ? parts[i][1] : parts[i]);
					const cellText = crossheet ? cellSheet+parts[i][1] : parts[i];

					const rangeName = /^[A-Za-z]+$/.test(parts[i]);
					if(activeSheet || (crossheet && parts[i][0] == sheet)){
						let repeatedColor;
						//range
						if((parts[i+1] == ":" && parts[i+2]) || rangeName){
							let nextCell, nextCellText;

							if(rangeName){
								const cells = ranges[parts[i]].split(":");
								cell = position(cells[0]);
								nextCell = position(cells[1]);
							}
							else{
								nextCell = position(crossheet ? parts[i+2][1] : parts[i+2]);
								nextCellText = crossheet ? cellSheet+parts[i+2][1] : parts[i+2];
								i+=2;
							}

							repeatedColor = this.setColor(cell[0], cell[1], nextCell[0], nextCell[1], colorIndex);
							html += `<span class="webix_ssheet_highlight_color_${repeatedColor||colorIndex}">${rangeName ? parts[i] : cellText+":"+nextCellText}</span>`;
						}
						//cell
						else{
							repeatedColor = this.setColor(cell[0], cell[1], cell[0], cell[1], colorIndex);
							html += `<span class="webix_ssheet_highlight_color_${repeatedColor||colorIndex}">${cellText}</span>`;
						}

						const colors = 7;
						if(!repeatedColor)
							colorIndex += colorIndex == colors ? -1*(colors-1) : 1;
					}
					else
						html += cellText;
				}
			}
		}
		else
			html = escape(value);

		this.getInputNode().innerHTML = html;
		master.$$("cells").refresh();

		const cursor = this.getDocumentCursor();

		if(webix.isUndefined(cursor))
			return;

		const cursorNode = cursor.startContainer;
		if(this.getInputNode().contains(cursorNode))
			this.paintValue();
	},
	changeCellCss(row, col, index, remove){
		const css = `webix_ssheet_highlight_background_${index}`;
		for (let r = row.start; r <= row.end; r++)
			for (let c = col.start; c <=  col.end; c++)
				this.getTopParentView().$$("cells")[remove?"removeCellCss":"addCellCss"](r, c, css, true);
	},
	getDocumentCursor(){
		const sel = document.getSelection();
		if(sel.rangeCount)
			return sel.getRangeAt(0);
	},
	focus(){
		const cursor = this.getDocumentCursor();

		if(!webix.isUndefined(cursor) && this.getInputNode().contains(cursor.startContainer)){
			const pos = this.getCursor();
			this.setCursor(pos);
		}
		else{
			const length = this.getValue().length;
			if(length)
				this.setCursor(length);
			else
				this.getInputNode().focus();
		}
	},
	endEdit(e){
		webix.UIManager.setFocus(this);
		let code = e.which || e.keyCode;

		//if it is a formula - force user to finish it by click outside or 'enter'
		if(code == 9 || (code>36 && code<41 && this.getValue().charAt(0)!=="=")){
			let dir = (code == 40)?"down":(code === 39?"right":(code ==37?"left":"up"));
			if (code === 9)
				dir = "right";

			this.updateCellValue();
			this.getTopParentView()._table.moveSelection(dir);
		}
	},
	paintValue(){
		let master = this.getTopParentView();
		if(!this._activeMath || this._activeMath == master.getActiveSheet()){
			let cell = this.config.activeCell;
			let node = master._table.getItemNode(cell);
			if(cell && node) 
				node.innerHTML = webix.template.escape(this.getValue());
		}
	},
	updateCellValue() {
		let newv = this.getValue();
		let master = this.getTopParentView();
		let cell = this.config.activeCell;

		if (!cell)
			this.setValue("");
		else if (newv != master.getCellValue(cell.row, cell.column)){
			this.config.value = newv;
			master.setCellValue(cell.row, cell.column, newv);
			master.refresh();
		}
	},
	$setValueHere(value){
		this.setValueHere(value);
	},
	setValueHere(value) {
		this._last_value_set = new Date();

		const formula = this.getValue();
		if(formula && formula.charAt(0)==="="){
			const cursor = this._cursor;
			let str1 = formula.substring(0, cursor);
			let str2 = formula.substring(cursor);
			const updatedStr = str1.replace(/([a-zA-Z(]+)$/, value);

			//suggest called via up/down key
			str1 = updatedStr == str1 ? str1 + value : updatedStr;

			this.setValue(str1 + "(" + str2);
			this.setCursor(str1.length + 1);
		}
	},
	expectRange(){
		let text = this.getValue().trim();
		let last = text.substr(text.length-1,1);
		return last.match(/[(,]/);
	},
	expectOperator(){
		let text = this.getValue();
		return text[0] == "=" && text[this._cursor-1] && text[this._cursor-1].match(/[+&\-/*%=(:,]/);
	},
	setRange(range, replace){
		const cursor = this._cursor;
		const formula = this.getValue();

		let str1, str2;

		//check if range was added by click
		if(replace)
			replace = this._update_range && cursor == this._update_range.pos + this._update_range.len;

		const lastPos = replace ? this._update_range.pos : cursor;

		str1 = formula.substring(0, lastPos) + range;
		str2 = formula.substring(cursor);

		this._update_range = {pos: lastPos, len: range.length};

		this.setValue(str1 + str2);
		this.setCursor(str1.length);
	},
	setCursor(pos){
		this._cursor = pos;
		let symbolsBefore = 0;
		const childs = this.getInputNode().childNodes;

		for(let i = 0; i < childs.length; i++){
			const textLength = childs[i].length||childs[i].innerText.length;

			if(pos - symbolsBefore <= textLength){
				const range = document.createRange();
				const sel = document.getSelection();
				range.setStart(childs[i].innerText ? childs[i].firstChild : childs[i], pos - symbolsBefore);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
				break;
			}
			else
				symbolsBefore += textLength;
		}
	},
	getCursor(){
		const cursor = this.getDocumentCursor();
		if(cursor){
			const pos = cursor.startOffset;
			let symbolsBefore = 0;

			const childs = this.getInputNode().childNodes;
			for(let i = 0; i < childs.length; i++){
				const parent = cursor.startContainer.parentNode;

				if(childs[i] == (parent.tagName == "SPAN" ? parent : cursor.startContainer))
					return symbolsBefore + pos;
				symbolsBefore += childs[i].innerText ? childs[i].innerText.length : childs[i].length;
			}
		}
		return this.getValue().length || 0;
	},
	prepareSheet(sheet){
		return (sheet.indexOf(" ") != -1 ? `'${sheet}'` : sheet) + "!";
	},
	prepareArea(first, second){
		return {
			start: Math.min(first, second),
			end: Math.max(first, second)
		};
	},
	setColor(row1, col1, row2, col2, color){
		const row = this.prepareArea(row1, row2);
		const col = this.prepareArea(col1, col2);
		const index = [row.start, row.end, col.start, col.end].join(",");
		const coloredArea = this._highlightedCells[index];

		if(!coloredArea){
			this._highlightedCells[index] = [row, col, color];
			this.changeCellCss(row, col, color);
		}
		else
			return coloredArea[2];
	}
}, webix.ui.template);