const group = {
	value: null,
	set: function(method, master){
		if(!this.value){
			this.start();
			method.call(master);
			this.end();
		}
		else
			method.call(master);
	},
	start(){
		this.value = webix.uid();
	},
	end(){
		this.value = null;
	}
};

export default group;