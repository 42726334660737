import {isCellLocked} from "./lock_cell";
import group from "./undo_group";


var ignoreUndoReset = false;

export function init(view){
	reset(view);
	undoHandlers(view);
	view.attachEvent("onHardReset", () => reset(view));
	view.attachEvent("onAfterSheetShow", () => reset(view));
	// styles
	view.attachEvent("onBeforeStyleChange", (row, column, nstyle, ostyle) =>{
		if (isCellLocked(view, row, column)) return false;
		addToHistory(view, {action: "style", row:row, column: column, value: ostyle, newValue: nstyle, group: group.value});
	});
	// editing
	view.attachEvent("onBeforeValueChange", (row, column, nvalue, ovalue) => {
		if (isCellLocked(view, row, column)) return false;
		addToHistory(view, {action: "value", row:row, column: column, value: ovalue, newValue: nvalue, group: group.value});
	});
	// merge cells
	view.attachEvent("onBeforeSpan", (row, column, value) => {
		if (isCellLocked(view, row, column)) return false;
		addToHistory(view, {action: "span", row:row, column: column, value: value, newValue:value, group: group.value});
	});
	// split cells
	view.attachEvent("onBeforeSplit", (row, column, value) => {
		if (isCellLocked(view, row, column)) return false;
		addToHistory(view, {action: "split", row:row, column: column, value: value, newValue:value, group: group.value});
	});
	// Any abstract undo actions
	view.attachEvent("onAction", (action, obj) =>
		addToHistory(
			view,
			{
				action: action,
				row: obj.row || null,
				column: obj.column || null,
				value: obj.value || null,
				newValue: obj.newValue || null,
				group: group.value
			}
		)
	);

	// column resize
	view.$$("cells").attachEvent("onColumnResize", (column, nvalue, ovalue) =>
		addToHistory(view, {action: "c-resize", row:0, column:column, value: ovalue, newValue: nvalue, group: group.value})
	);
	// column hide
	view.attachEvent("onColumnOperation", (action, start, end) => {
		if (action.id !== "add" && action.id !== "del")
			addToHistory(view, { action:action, row:0, column:{ start, end } });
	});
	// row hide
	view.attachEvent("onRowOperation", (action, start, end) => {
		if (action.id !== "add" && action.id !== "del")
			addToHistory(view, { action:action, row:{ start, end }, column:0 });
	});
	// row resize
	view.$$("cells").attachEvent("onRowResize", (row,  nvalue, ovalue) =>
		addToHistory(view, {action: "r-resize", row: row, column:0, value: ovalue, newValue: nvalue, group: group.value})
	);

	// condition formats
	view.attachEvent("onBeforeConditionSet", (row, column, value, nvalue) => {
		if (isCellLocked(view, row, column)) return false;

		value = value ? webix.copy(value) : null;
		addToHistory(view, {action: "condition", row: row, column: column, value: value, newValue: nvalue, group: group.value});
	});
	view.$skipHistory = 0;
}

export function undo(view){
	restoreHistory(view,-1);
}

export function redo(view){
	restoreHistory(view,1);
}

export function reset(view){
	if(!ignoreUndoReset){
		view._ssUndoHistory = [];
		view._ssUndoCursor = -1;
	}
}

function addToHistory(view, data){
	if(!view.$skipHistory){
		// remove futher history
		view._ssUndoHistory.splice(view._ssUndoCursor+1);
		// add to an array and increase cursor
		view._ssUndoHistory.push(data);
		view._ssUndoCursor++;
	}
}

export function ignoreUndo(func, view){
	view.$skipHistory++;
	try{
		func.call(view);
	}
	finally {
		view.$skipHistory--;
	}
}

export function ignoreReset(func){
	ignoreUndoReset = true;
	func();
	ignoreUndoReset = false;
}

function restoreHistory(view, step){
	var data = view._ssUndoHistory[step>0?(view._ssUndoCursor+step):view._ssUndoCursor];
	if(data){
		var value = step > 0 ? data.newValue : data.value;
		var direction = step>0;
		var params = [data.action, data.row, data.column, value, direction];

		view._ssUndoCursor += step;

		// group support
		var group = data.group;
		var prevData = view._ssUndoHistory[step>0?(view._ssUndoCursor+step):view._ssUndoCursor];

		ignoreUndo(function(){
			view.callEvent("onUndo", params);
			if(!group || !(prevData && group == prevData.group))
				view.refresh();
		},view);

		if(prevData && group && group == prevData.group){
			restoreHistory(view, step);
		}
	}
}

function undoHandlers(view){
	view.attachEvent("onUndo", function(type, row, column, value) {
		switch (type) {
			case "freeze-row":
				view.freezeRows(value);
				break;
			case "freeze-column":
				view.freezeColumns(value);
				break;
		}
	});
}