import {Dialog} from "./common";
import * as fmt from "../operations/formats";
import group from "../operations/undo_group";

export const action = "custom";

export class DialogBox extends Dialog{
	$show(){
		this.form = this.$dialog.$$("form");
		this.list = this.$dialog.$$("list");
		this.preview = this.$dialog.$$("preview");

		this.cell = this.view.getSelectedId(true);
		if (!this.cell.length)
			return false;

		this.fillForm(this.view);

		this.view.$handleSelection = function(){
			return false;
		};
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		const locale = webix.i18n.spreadsheet.labels;
		const docsLink = "https://webix-guides.gitbook.io/spreadsheet-user-guide/formatting_numbers#custom-number-format";
		const delimiters = fmt.getDelimiters("int");
		return {
			view: "ssheet-dialog",
			position:"center",
			width:460,
			head: locale["format-title"],
			move: true,
			body:{
				cols:[
					{ view:"list", id:"list", css:"webix_ssheet_format_type", width:120, scroll:false, data:this.getFormats(), select:true,
						on:{
							onSelectChange: (id)=>{
								this.updateForm();
								if(id[0] === "custom")
									this.form.showBatch("custom");
								else{
									this.form.showBatch("common");
									this.form.elements.separator.show();
									if(id[0] === "percent")
										this.form.elements.separator.hide();
								}
							}
						}
					},
					{ view:"form", id:"form", margin:15, height:270, paddingY:0, paddingX:20,
						elements:[
							{ template: function(obj){
								return "<span class ='"+obj.css+"'>"+obj.value+"</span>";
							}, css: "webix_ssheet_format_preview", autoheight: true, id: "preview", borderless:true},
							{ view:"counter", css:"webix_ssheet_counter", label:locale["decimal-places"], name:"zeros", value:0, batch:"common" },
							{ view:"checkbox", label:locale["separator"], name: "separator", batch:"common" },
							{ view:"formlist", label:locale["negative"], name:"negative", batch:"common", css:"webix_ssheet_format_negative",
								template: (obj)=>{
									if(this.list){
										let format = this.getFormat(obj.id);
										format = fmt.format2code(format.fmt, format.delimiters);
										let style = {css:""};
										let value = format(-1234.56789, style);

										return "<span class='"+style.css+"'>"+value+"</span>";
									}
								},
								data:[{id:1}, {id:2}, {id:3}]
							},
							{ view:"text", label: locale["format-pattern"], name:"format", labelPosition:"top", batch:"custom",
								placeholder:"[>100]0"+delimiters.groupSign+"000"+delimiters.decimalSign+"00;[>0]None" },
							{ template: "<a href='"+docsLink+"' target='_blank' class = 'docs'>"+locale["format-docs"]+"</a>",
								borderless:true, batch:"custom", id: "docs"}
						],
						elementsConfig: {
							labelWidth: 155
						},
						on: {
							onChange: () => this.updateForm()
						}
					}
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onHideClick: () => this.close(),
				onCancelClick: () => this.closeWin()
			}
		};
	}
	okClick(){
		let type = this.list.getSelectedId();
		let values = (type == "custom")?{format:this.form.getValues().format}:this.form.getValues();
		values.type = type;

		group.set(()=>{
			for (let i = 0; i < this.cell.length; i++)
				fmt.addFormat(this.view, this.cell[i].row, this.cell[i].column, values.format, values);
		});

		this.view.$$("cells").refresh();
		this.closeWin();
	}
	closeWin(){
		const style = this.view.getStyle(this.cell[0].row, this.cell[0].column);
		const name = style && style.props.format ? style.props.format : "common";

		//default format
		if(!fmt.isCustom(name))
			this.view.$$("bar").setValues({format:name}, true);
		this.close();
	}
	getFormat(negative){
		const type = this.list.getSelectedId();
		let values = this.form.getValues();

		values.negative = negative || values.negative;
		if(type !== "custom"){
			values = fmt.formToValues(type, values);
			return {
				fmt: fmt.serializeFormat(values),
				delimiters:fmt.getDelimiters(type)
			};
		}
		else
			return {
				fmt: values.format,
				delimiters: fmt.getDelimiters(type)
			};
	}
	fillForm(view){
		const style = view.getStyle(this.cell[0].row, this.cell[0].column);
		let values = { zeros:0, separator: 0, negative: 1, format: ""};
		let type;

		let name = style && style.props.format ? style.props.format : "";

		if(!name || name == -1)
			type = name = "price";
		else
			type = fmt.formatHelpers[name].values.type;

		values.format = fmt.getFormatSource(name);
		if(type !=="custom")
			webix.extend(values, fmt.formatHelpers[name].values, true);

		let value = this.view.$$("cells").getItem(this.cell[0].row)[this.cell[0].column];
		if(value === "" || isNaN(value))
			value = 1234.56789;
		this.value = value;

		this.form.setValues(values);
		this.list.select(type);
		this.updateForm();
	}
	updateForm(){
		const form = this.form.elements;

		const format = this.getFormat();
		let style = {css:""};
		let value = fmt.format2code(format.fmt, format.delimiters)(this.value*1, style);
		
		//preview values
		this.preview.parse({value:value, css:style.css});
		form.negative.refresh();
		
		//set new format string
		form.format.setValue(format.fmt);
	}
	getFormats(){
		const locale = webix.i18n.spreadsheet.labels;
		return [
			{id: "price", value: locale.currency},
			{id: "int", value: locale.number},
			{id: "percent", value: locale.percent},
			{id: "custom", value: locale["custom-format"]}
		];
	}
}