import group from "../operations/undo_group";

/* row operations */

export function insertRow(row){
	callCommand(this, "add", "row", row);
}

export function deleteRow(row){
	callCommand(this, "del", "row", row);
}

export function setRowHeight(row, height){
	setSize(this, "row", row, height);
}

/* col operations */

export function insertColumn(column){
	callCommand(this, "add", "column", column);
}

export function deleteColumn(column){
	callCommand(this, "del", "column", column);
}

export function setColumnWidth(column, width){
	setSize(this, "column", column, width);
}

/* helpers */

function getCells(view, id, group){
	if(!id){
		let area = view.$$("cells").getSelectArea();
		if(area)
			id = [area.start[group],area.end[group]];
	}
	else if(!webix.isArray(id)){
		id =  [id,id];
	}
	return id;
}

function callCommand(view, name, group, id){
	let start = {}, end = {};
	id = getCells(view, id, group);
	if(!id)
		return;
	start[group] = id[0];
	end[group] = id[1];
	view.callEvent("onCommand", [{id:name,  group:group}, start, end]);
}

function setSize(view, type, id, size){
	id = getCells(view, id, type);
	if(!id)
		return;
	group.set(()=>{
		for (let i = id[0]; i <= id[1]; i++)
			view.$$("cells")[type == "row" ? "setRowHeight" : "setColumnWidth"](i, size);
	});
}