import {rangeValue} from "../math/ranges";
import {changeRange, eachRange} from "../helpers/column_names";
import {toRange} from "../helpers/column_names";

export function init(view){
	var table = view._table;
	view.attachEvent("onReset", () => reset(view));

	reset(view);
	table.attachEvent("onBeforeEditStart", function(id){
		let col = this.getColumnConfig(id.column);
		if (this._ssEditors[id.row]){
			let ed = this._ssEditors[id.row][id.column];
			if (ed){
				webix.extend(col, ed, true);
				if (ed.options){
					if (typeof ed.options == "string")
						col.options = rangeValue(view, ed.options, { unique: true, empty:true, order:true, filter: ed.useAsFilter });
					else if(webix.isArray(ed.options))
						col.options = rangeValue(view, ed.options, { empty:true, filter: ed.useAsFilter });
				}
			}
		}
	});
	table.attachEvent("onAfterEditStop", function(state, id){
		let col = this.getColumnConfig(id.column);
		col.editor = "text";
		delete col.popup;
		delete col.$popup;

		if (isFilter(view, id.row, id.column))
			return view.filterSpreadSheet();
	});

	table.on_click.ss_filter = function(e, id){
		this.edit(id);
	};

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(p.name,p.inc,p.data, p.start);
	});
}

function reset(view){
	view._table._ssEditors = {};
	view._table._ssFilters = {};
}

export function serialize(view,data){
	var row, column,
		tblEditors = view._table._ssEditors,
		editors = [];

	for(row in tblEditors){
		for(column in tblEditors[row]){
			if(!isFilter(view, row, column))
				editors.push([row, column, tblEditors[row][column]]);
		}
	}
	data.editors = editors;
}

export function load(view,data){
	var i,
		editors = data.editors;

	if(editors)
		for(i = 0; i < editors.length; i++)
			view.setCellEditor.apply(view,editors[i]);
}




export function clearEditors(view, range){
	var refilter = false;

	view._table.editStop();
	eachRange(range, view, function(view, cell){
		let editor = view._table._ssEditors[cell.row];
		let filter = view._table._ssFilters[cell.row];

		if (editor){
			if (editor[cell.column]){
				delete editor[cell.column];
				view._table.removeCellCss(cell.row, cell.column, "ss_filter");
			}
		}
		if (filter){
			if (filter[cell.column]){
				delete filter[cell.column];
				refilter = true;
			}
		}
	});

	if (refilter)
		view.filterSpreadSheet();
}



export function isFilter(view, row, column){
	var filters = view._table._ssFilters;
	return filters[row] && filters[row][column];
}

export function addCellFilter(view, row, column, initial){
	var filters = view._table._ssFilters;
	var line = filters[row] = filters[row] || {};
	line[column] = [row,column];

	view.callEvent("onAction", ["addFilter", { row, column }]);
	if(!initial && view.getCellValue(row,column) != "")
		view.setCellValue(row,column,"");
}

// called on column/row add, delete, hide,show
function updatePosition(name, inc, data, start){
	let i,
		editors = data.editors;

	if(inc){
		// create new editors
		for(i = editors.length-1;i >=0;i--){
			let [row,column,editor] = editors[i];
			if(name == "row" && row>= start.row || name == "column" && column >= start.column){
				//make copy of editor object
				editor = editors[i][2] = webix.copy(editor);

				if (name == "row"){
					if (row < start.row - inc)	//delete lock mark if row was deleted
						editors.splice(i,1);
					else						//update mark position if upper row was deleted
						editors[i][0] = row*1 + inc;
				} else if (name == "column"){
					if (column < start.column - inc){
						editors.splice(i, 1);
					} else 
						editors[i][1] = column*1 + inc;
				}
			}
			editor.options = changeRange(editor.options, name, inc, start);
		}
	}
}

export function pasteDropdown(view, extra, row, col, cutted, translate, updateFilters){
	var editor = extra.dropdown.editor;
	if(extra.dropdown.filter){
		if(updateFilters){
			var  options = changeRange(editor.options, "row", translate.row, {column:extra.col, row:extra.row});
			options = changeRange(options, "column", translate.column, {column:extra.col, row:extra.row});
			view.setCellFilter(row, col, options);
			view.callEvent("onAction", ["filter", { row, column:col, value:null, newValue:{options:options} }]);
		}
	}
	else {
		view.setCellEditor(row, col, editor);
		if(cutted === 1)
			view.setCellEditor(extra.row, extra.col, {});
	}
}

export function setFilter(view, row, column, range, initial){
	if(!range)
		range = {end:{row:row}};

	if (range.end){
		var endRow = range.end.row;
		range = getFilterRange(view, row, column, endRow);
	}
	view.setCellEditor(row,column, { editor:"richselect", options: range, useAsFilter:true });
	addCellFilter(view, row, column, initial);
}

function getFilterRange(view,  row, column, endRow){
	if (row === endRow){
		for (endRow = row; endRow < view.config.rowCount; endRow++){
			if(!view.getCellValue(endRow+1,column))
				break;
		}
	}
	return toRange(row+1, column, endRow, column);
}