//TODO
// ### column deleting | adding
// recalculate positions

// ### copy paste between sheets
// force sheet name

// ### triggers
// a <- p <- a
// register triggers from passive sheet

// ### sheet renaming
// adjust math on sheet rename
// adjust ranges on sheet rename

import {getActive, getSheet} from "../sheets";
import {range} from "../helpers/column_names";
import {clearMathCache} from "./cache";
import {updateSheetNames} from "./updater";


export function init(view, core, helpers){
	view.attachEvent("onBeforeSheetShow", function(){
		delete view._mathSheetCache[getActive(view)];
	});
	view.attachEvent("onSheetRename", function(name, newName){
		view._sheets.forEach((sheet)=>{
			let extra = {multiSheet:false};
			const edit = ["data", "editors", "conditions", "ranges"];
			edit.forEach((param)=>{
				if(sheet.content[param])
					sheet.content[param].forEach((value)=>{
						switch (param) {
							case "data":
								if(value[2][0] === "=")
									value[2] = updateSheetNames(value[2], name, newName, extra);
								break;
							case "editors":
								value[2].options = updateSheetNames(value[2].options, name, newName, extra);
								break;
							case "conditions":
								if(value[3][0] === "=")
									value[3] = updateSheetNames(value[3], name, newName, extra);
								break;
							case "ranges":
								value[1] = updateSheetNames(value[1], name, newName, extra);
								break;
						}
					});
			});
			if(extra.multiSheet === true){
				delete view._mathSheetCache[sheet.name];
				getAccessor(view, sheet.name);
				clearMathCache();
			}
		});
		delete view._mathSheetCache[name];
	});
	view.attachEvent("onSheetRemove", function(name){
		delete view._mathSheetCache[name];
	});

	view._mathSheetCache = { };
	view._mathSheetCore = core;
	view._mathSheetHelpers = helpers;
}

export function getAccessor(view, name){
	//actuve sheet accessor
	if (!name) 
		return SheetAccessor(activeAccessor(view));

	//passive sheet accessor
	if (!view._mathSheetCache[name]){
		let temp = view._mathSheetCache[name] = (name === getActive(view)) ? 
			SheetAccessor(activeAccessor(view)) :
			SheetAccessor(passiveAccessor(view, name));
		temp.init();

	}

	return view._mathSheetCache[name];
}

function SheetAccessor(helpers){
	return {
		init:function(){
			helpers.init();
		},
		getRangeCode(name){
			return helpers.getRangeCode(name);
		},
		getValue(r,c){
			var val = helpers.getCell(r,c);
			var test = val*1;
			if (!isNaN(test)) return test;
			return val?val:"";
		},
		getRangeValue(name){
			var code = this.getRangeCode(name) || name;
			if (code.indexOf(":") == -1) return [];
			var pos = range(code);
			return this.getRange.apply(this, pos);
		},
		getRange(r1,c1,r2,c2){
			var set = helpers.getRange(r1,c1,r2,c2);
			for (var i = set.length-1; i>=0; i--){
				let val = set[i];
				let test = val !== "" ? val * 1 : "";
				set[i] = isNaN(test)?(val?val:""):test;
			}
			return set;
		}
	};
}

function activeAccessor(view){
	return {
		init:function(){

		},
		getRangeCode(name){
			return view.ranges.getCode(name);
		},
		getRow:function(r){
			return view.getRow(r);
		},
		getCell:function(r,c){
			return view.getRow(r)[c];
		},
		getRange:function(r1,c1,r2,c2){
			var set = [];
			for (var i = r1; i <= r2; i++){
				var item = view.getRow(i);
				for (var j = c1; j <= c2; j++)
					set.push(item[j]);
			}
			return set;
		}
	};
}

function passiveAccessor(view, name){
	const page = getSheet(view, name).content;
	const table = [];
	const formulas = [];

	const ranges = [];

	return {
		init:function(){
			if (page.ranges){
				for (var i = 0; i < page.ranges.length; i++) {
					var line = page.ranges[i];
					ranges[line[0]] = line[1];
				}
			}

			if (page.data){
				for (let i = 0; i < page.data.length; i++) {
					//expand data structure
					let [row, column, value] = page.data[i];
					if (!table[row])
						table[row] = [];
					table[row][column] = value;

					if (value[0] === "="){
						//for math cells, store the code
						let math = view._mathSheetHelpers.parse(value, view._mathSheetCore, name);
						table[row][column] = math.handler;

						//store list of cross-sheet formulas
						for (var j=0; j<math.triggers.length; j++){
							if (math.triggers[j][5]){
								formulas.push(page.data[i]);
								break;
							}
						}
					}
				}
			}
		},
		getRangeCode(name){
			return ranges[name];
		},
		getRow(i){
			return table[i] || [];
		},
		getCell(i, j){
			var value = this.getRow(i)[j];
			if (typeof value === "function")
				return view._mathSheetHelpers.execute(value, view._mathSheetCore);

			return value;
		},
		getRange:function(r1,c1,r2,c2){
			var set = [];
			for (var i = r1; i <= r2; i++)
				for (var j = c1; j <= c2; j++)
					set.push(this.getCell(i,j));
			return set;
		}
	};
}