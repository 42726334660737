webix.protoUI({
	name: "sheets",
	defaults:{
		layout: "x",
		borderless: true,
		css:"ssheet_list_sheets",
		select: true,
		drag: "order",
		dragscroll:"x",
		scroll:false
	}
},webix.EditAbility,webix.ui.list);